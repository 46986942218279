import { Link } from "gatsby"
import React, {useState} from "react"
import Layout from "../../components/layout"
import RequestDemo from "../../components/requestDemo"
import SEO from "../../components/seo"



const EcommerceServicesPage = () => {
  return (
    <Layout>
      <SEO title="Изработка на онлайн магазин"/>
      <div className='container'>
        <h1>Екомерс услуги - изградете своят нов онлайн магазин</h1>
        <section className="container my-5 py-2" id="restaurants">
          <h2>Професионална изработка на онлайн магазин (екомерс) </h2>
          <RequestDemo formName="ecommerce-request-demo"/>
        </section>
        <Link to="/contact/"><button className="btn btn-lg btn-cobalt align-center fs-2 mb-5">Свържете се с нас сега</button></Link>
      </div>
    </Layout>)
}

export default EcommerceServicesPage